import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    videoId: String
  }

  static targets = ["button"]

  connect() {
    // this.refresh()
  }

  refresh() {
    const refreshTime = 15 * 1000

    setTimeout(() => {
      const isProcessing = JSON.parse(this.element.dataset.isProcessing)
      if (isProcessing) {
        window.location.reload()
      }
    }, refreshTime)
  }

  async download(event) {
    event.preventDefault()

    const button = this.buttonTarget
    const buttonText = document.getElementById("download-text")
    const originalText = buttonText.innerHTML
    buttonText.innerHTML = "Downloading..."
    button.disabled = true

    try {
      const response = await fetch(`/account/video/download-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          video_id: this.videoIdValue
        })
      })

      const data = await response.json()

      if (data.download_url) {
        const downloadUrl = data.download_url + "?download=" + document.querySelector('[data-file-name]').dataset.fileName
        window.location.href = downloadUrl
      } else {
        throw new Error('No download URL received')
      }
    } catch (error) {
      console.error('Download failed:', error)
      alert('Failed to start download. Please try again or contact support if the issue persists.')
    } finally {
      buttonText.innerHTML = originalText
      button.disabled = false
    }
  }
}
