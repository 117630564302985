import { Controller } from "@hotwired/stimulus"
import { Notyf } from "notyf"

export default class extends Controller {
  static targets = [ "label", "form" ]

  connect() {
    this.notyf = new Notyf({
      duration: 5000,
      dismissible: true,
      position: {
        x: 'center',
        y: 'bottom',
      }
    });
    this.currentLabel = this.labelTarget.value
    this.isSubmitting = false
    window.Uatu.on("color_change", () => {
      this.formTarget.requestSubmit()
    })
  }

  enableEditing() {
    this.labelTarget.readOnly = false
    this.labelTarget.focus()
  }

  save(event) {
    if ((this.currentLabel === this.labelTarget.value) || this.isSubmitting) {
      return
    }

    event.preventDefault()
    this.formTarget.requestSubmit()
    this.isSubmitting = true

    setTimeout(()=>{
      this.isSubmitting = false
    }, 500 )
  }

}
